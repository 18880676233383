.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.wrapper > span {
  background: var(--main-color);
  color: var(--secondary-color);
  font-weight: 500;
}

.input {
  width: 48px;
  height: 48px;
}

.label {
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  color: var(--main-color);
}

@media (max-width: 1300px) {
  .wrapper > span {
    border-radius: 10px;
    padding: 8px 16px;
    font-size: 32px;
    line-height: 39px;
  }

  .input {
    width: 64px;
    height: 64px;
    border-radius: 10px;
    overflow: hidden;
  }

  .label {
    font-size: 36px;
  }
}
