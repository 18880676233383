.device-card {
  border-width: 3px;
  border-style: solid;
  border-radius: 20px;
  padding: 16px 0;
  cursor: pointer;
  width: 146px;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background-zkps);
}

.device-card > p {
  padding: 0;
  margin: 0;
}

.device-card__top-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  text-align: center;
}

.device-card__bottom-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: var(--main-color)
}

.device-card:hover {
  opacity: 0.9;
}

@media (max-width: 1300px) {
  .device-card {
    border-radius: 16px;
    padding: 12px 0;
    max-width: 176px;
    width: 100%;
    height: 197px;
  }

  .device-card__top-text,
  .device-card__bottom-text {
    font-size: 24px;
    line-height: 29px;
  }
}
