.card {
  height: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 3px solid rgba(120, 139, 177, 0.25);
  background: var(--background, #f5f9fa);
  color: rgba(120, 139, 177, 0.25);
}

.card > h2 {
  text-align: center;
  font-family: Montserrat;
  font-size: 52px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2.6px;
  text-transform: uppercase;
}

.card > p {
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.active {
  color: var(--main-color);
  background-color: var(--secondary-color);
  border-color: var(--main-color);
}

.alarm {
  color: #fff;
  background: var(--danger-color);
}

.malfunction {
  color: #fff;
  background: #ff8125;
}

.disabled {
  color: #fff;
  background: var(--warn-color);
}

@media (max-width: 1300px) {
  .card {
    height: 96px;
  }

  .card > p {
    font-size: 24px;
  }
}
