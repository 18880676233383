.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.card p {
  font-size: 24px;
  color: #788bb1;
  font-weight: 600;
}

.buttons {
  display: flex;
  gap: 12px;
}

.card button {
  font-size: 20px;
  padding: 12px 16px;
  color: white;
}

.buttonStart {
  background: rgb(71, 193, 71);
}
.buttonStop {
  background: rgb(188, 74, 74);
}
.buttonReload {
  background: #788bb1;
}

@media (max-width: 1300px) {
  .card {
    padding: 6px 0;
  }

  .card p {
    font-size: 12px;
  }

  .buttons {
    gap: 6px;
  }

  .card button {
    font-size: 10px;
    padding: 6px 8px;
  }
}
