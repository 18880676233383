.card {
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 16px;
  background: rgba(120, 139, 177, 0.25);
  align-items: flex-end;
  border-radius: 24px;
}

.card_indicator {
  text-decoration: none;
  border-radius: 20px;
  max-width: 272px;
  width: 100%;
  height: 196px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
  text-align: center;
  font-family: Montserrat;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card_indicator > span {
  font-size: 36px;
  line-height: 44px;
}

.card_devices_wrapper {
  width: 412px;
  overflow-x: auto;
  scrollbar-width:none;
}


.card_devices {
  display: flex;
  gap: 16px;
  width: 1000000px; 
}


.text_box {
  max-width: 440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Montserrat;
}

.text_box > p {
  color: #a5b0c6;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}
