.card {
  margin-bottom: 16px;
}

.smallMenu {
  align-self: flex-start;
  right: 0;
  top: 0;
  position: absolute;
}

.modal {
  position: relative;
  border-radius: 20px;
  max-width: 1520px;
}

@media (max-width: 1795px) {
  .modal {
    max-width: 1300px;
  }
}
