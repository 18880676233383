.grid {
  width: 100%;
  height: 272px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
}

@media (max-width: 1300px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-auto-rows: 0;
    height: auto;
    overflow-y: hidden;
    row-gap: 0;
  }
}
