.active-events___list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 1300px) {
  .active-events___list {
    gap: 8px;

    height: calc(100vh - 88px - 16px - 16px);
    overflow-y: scroll;
  }
}
