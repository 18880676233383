.device-card__img {
  padding: 14px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8px 0;
}

.device-card__img > img {
  height: 92px;
  object-fit: cover;
}

@media (max-width: 1300px) {
  .device-card__img {
    padding: 8px 0;
    margin: 8px 0;
  }

  .device-card__img > img {
    height: 84px;
  }
}
