.room-page {
  width: 100%;
}

.room-page__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 28px;
  position: relative;
}

.room-page__devices {
  display: grid;
  grid-template-columns: repeat(5, 146px);
  grid-template-rows: repeat(3, 220px);
  row-gap: 16px;
  column-gap: 16px;
}

.room-page__info {
  max-width: 580px;
  width: 100%;
}

.room-page__info-main,
.room-page__info-side {
  display: flex;
  flex-direction: column;
}

.room-page__info-main {
  margin-bottom: 32px;
  gap: 24px;
}

.room-page__info-side {
  gap: 14px;
}

.room-page__toolbar {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
}

.toolbar__right-buttons {
  display: flex;
  gap: 14px;
}

.room-page__toggle-menu {
  position: absolute;
  right: 0px;
}

@media (max-width: 1300px) {
  .room-page__devices {
    grid-template-columns: repeat(3, minmax(auto, 176px));
    grid-template-rows: auto;
    row-gap: 16px;
    column-gap: 16px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 88px - 16px - 36px);
    max-width: 560px;
    width: 100%;
  }

  .room-page__wrapper {
    gap: 16px;
  }

  .room-page__info {
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 88px - 16px - 36px);
  }

  .room-page__info-main {
    gap: 16px;
    
  }

  .room-page__info-side {
    padding-top: 48px;
    gap: 8px;
  }

  .room-page__toolbar {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  .toolbar__right-buttons {
    display: flex;
    gap: 16px;
  }
}
