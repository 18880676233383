.universal-device-image {
  width: 284px;
  height: 217px;
  border: 3px solid var(--main-color);
  border-radius: 20px;
  overflow: hidden;
  background: var(--secondary-color);
}

.universal-device-image > img {
  width: 100%;
}

@media (max-width: 1300px) {
  .universal-device-image {
    width: 272px;
    height: 216px;
  }
}
