.universal-device-info {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  gap: 28px;
  margin-bottom: 44px;
}

.universal-device-info__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.universal-device-info__menu {
  align-self: flex-start;
  right: 0;
  top: 0;
  position: absolute;
}

@media (max-width: 1300px) {
  .universal-device-info__column  {
    justify-content: space-between;
    height: 216px;
  }
  .universal-device-info {
    margin-bottom: 32px;
  }
  

}
