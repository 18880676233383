.title {
  color: #788bb1;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  line-height: 34px;
  align-self: center;
}

@media (max-width: 1300px) {
  .title {
    font-size: 18px;
  }
}
