.universalDeviceTabs {
  display: flex;
  gap: 16px;
  width: 20200px;
}

.universalDeviceTabs a {
  border-radius: 48px;
  border: 3px solid var(--main-color);
  background: #fff;
  padding: 30px 32px;
  color: var(--main-color);
  text-align: center;
  font-family: Montserrat;
  text-decoration: none;
}

.universalDeviceTabs .active {
  border-radius: 48px;
  background: var(--main-color);
  color: var(--secondary-color);
}

.link_large a {
  text-align: center;
  font-size: 48px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 128px;
  height: 128px;
  border-radius: 50% !important;
}

.link_default a {
  font-size: 22px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

@media (max-width: 1300px) {
  .universalDeviceTabs {
    gap: 8px;
  }

  .universalDeviceTabs a {
    border-width: 2px;
    padding: 15px 16px;
  }

  .link_large a {
    font-size: 24px !important;
    width: 64px;
    height: 64px;
  }

  .link_default a {
    font-size: 12px !important;
  }
}
