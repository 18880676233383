.app-wrapper {
  display: flex;
  background: #f5f9fa;
  min-height: 100vh;
}

.app-body {
  box-sizing: border-box;
  max-height: 100vh;
  width: 100%;
  padding: 30px 40px;
  overflow: hidden;
  position: relative;
}

html {
  width: 100%;
}

@media (max-width: 1300px) {
  .app-body {
    padding: 16px 16px 0;
  }
}
