.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.categories {
  display: flex;
  gap: 28px;
}

@media (max-width: 1300px) {
  .categories {
    gap: 14px;
  }
}
