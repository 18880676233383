.topInfoBar {
  display: flex;
  gap: 80px;
}

.topInfoBar__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.topInfoBar__title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #a5b0c6;
}

.topInfoBar__row {
  display: flex;
  align-items: center;
  gap: 32px;
}

.topInfoBar__row > h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 51px;
  color: #788bb1;
}

.topInfoBar__statusesBox {
  display: flex;
  gap: 8px;
}

@media (max-width: 1300px) {
  .topInfoBar {
    gap: 40px;
  }

  .topInfoBar__card {
    gap: 4px;
  }

  .topInfoBar__title {
    font-size: 12px;
    line-height: 15px;
  }

  .topInfoBar__row {
    gap: 16px;
  }

  .topInfoBar__row > h3 {
    font-size: 20px;
    line-height: 25px;
  }

  .topInfoBar__statusesBox {
    gap: 4px;
  }
}
