@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

html,
body {
  overscroll-behavior-y: none;
}

::-webkit-scrollbar { /* chrome based */
  width: 0px;  /* ширина scrollbar'a */
  background: transparent;  /* опционально */
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* 
  * 7` ( 1024 x 600 ) screen - max-width: 1200px
  * 8` ( 1280 x 720 ) screen - max-width: 1300px
  * middle ( 1920 x 1080 ) screen
  * 9.7` ( 2048 x 1536 ) - min-width: 2000px
  
  */
  --main-color: #788bb1;
  --secondary-color: white;
  --disabled-color: #d5ddec;

  --warn-color: rgb(201, 191, 2);
  --danger-color: rgb(182, 2, 2);
  --service-color: #007be4;
  --initial-color: rgb(128, 128, 128);
  --test-color: #9765c0;
  --founded-color: rgb(0, 128, 119);
  --offline-color: rgb(99, 99, 99);
  --connection-color: #258ecb;

  --success-color: #25cb55;
  --fire2-color: #d0021b;
  --fire1-color: #d623a3;
  --malfunction-color: #ffbd0a;
  --unconnection-color: #ff8125;
  --automatic3-color: #29ABE2;

  --background-zkps: rgb(255, 251, 233);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

.thin-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: var(--main-color);
}

.medium-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--main-color);
}

button,
input {
  outline: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.special-button {
  text-decoration: none;
  border-radius: 20px;
  border: 3px solid var(--main-color);
  background: var(--secondary-color);
  min-width: 280px;
  min-height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  text-align: center;
  font-size: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.special-button:active {
  background: var(--main-color);
  color: var(--secondary-color);
}

.special-button:disabled {
  background: rgba(255, 255, 255, 0.75);
  border: 3px solid rgba(120, 139, 177, 0.25);

  color: #788bb140;
}

.primary-button {
  text-decoration: none;
  border-radius: 48px;

  background: var(--main-color);
  color: var(--secondary-color);
  min-width: 280px;
  min-height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.primary-button:active {
}

.primary-button:disabled {
  background: rgba(255, 255, 255, 0.75);
  border: 3px solid rgba(120, 139, 177, 0.25);
  color: #788bb140;
}

.secondary-button {
  text-decoration: none;
  border-radius: 48px;
  border: 3px solid var(--main-color);
  background: var(--secondary-color);
  min-width: 280px;
  min-height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  text-align: center;
  font-size: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
 
}

.secondary-button:active {
  background: var(--main-color);
  color: var(--secondary-color);
}

.secondary-button:disabled {
  background: rgba(255, 255, 255, 0.75);
  border: 3px solid rgba(120, 139, 177, 0.25);
  color: #788bb140;
}

@media (max-width: 1300px) {
  .primary-button,
  .secondary-button,
  .special-button {
    box-sizing: border-box;
    padding: 30px 28px;
    line-height: 27px;
    
min-height: auto;
min-width: auto;
    /* border-width: 2px;
    min-width: 140px;
    min-height: 44px;
    font-size: 12px; */
  }

  .primary-button,
  .secondary-button {
    /* border-radius: 24px; */
  }

  .special-button {
    /* border-radius: 6px; */
  }
}
