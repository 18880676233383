.universal-device-options {
  padding: 48px 24px 0;
}

.universal-device-options__grid {
  display: flex;
  gap: 62px;
}

.universal-device-options__grid-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 1300px) {
  .universal-device-options {
    padding: 24px 12px 0;
  }

  .universal-device-options__grid {
    gap: 30px;
  }
  
  .universal-device-options__grid-column {
    gap: 8px;
  }
}
