.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.wrapper > p {
  max-width: 146px;
  color: var(--main-color);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 72px;
  border-radius: 20px;
  background: #007be4;
  color: white;
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input:disabled {
  color: #c4ccda;
}

@media (max-width: 1300px) {
  .wrapper {
    gap: 8px;
  }

  .wrapper > p {
    max-width: 74px;
    font-size: 12px;
  }

  .input {
    width: 72px;
    height: 32px;
    border-radius: 10px;
    font-size: 18px;
  }
}
