.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  color: var(--main-color);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.subtitle {
  color: var(--main-color);
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

@media (max-width: 1300px) {
  .title {
    font-size: 22px;
  }

  .subtitle {
    font-size: 42px;
  }
}
