.card {
  border-radius: 24px;
  display: flex;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

@media (max-width: 1300px) {
  
}
