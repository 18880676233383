.wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 32px;
}

@media (max-width: 1300px) {
  .wrapper {
    grid-gap: 20px;
  }
}
