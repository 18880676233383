.wrapper {
  width: 812px;
}

.title {
  color: var(--secondary-color);
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  line-height: 74px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 20px 0;
  display: block;
}

.body {
  background-color: #f5f9fa;
  border-radius: 20px;
  padding: 32px;
}

@media (max-width: 1300px) {
  .wrapper {
    width: 436px;
  }

  .title {
    font-size: 14px;
    line-height: 24px;
    padding: 10px 0;
  }

  .body {
    border-radius: 10px;
    padding: 16px;
  }
}
