.event__wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

@media (max-width: 1300px) {
  .event__wrapper {
    gap: 32px;
  }
}
