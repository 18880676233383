.table {
  border-radius: 20px 20px 0px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}

.circle {
  color: var(--malfunction-color);
  font-size: 32px;
}

.table__row_head,
.table__row {
  display: grid;
  grid-template-columns: 40px 130px 150px 130px 1fr;
}

.table__row_head {
  border-radius: 20px 20px 0px 0px;
  background: var(--main-color);
  position: sticky;
  top: 0;
}

.table__row {
  background: #f5f9fa;
  text-decoration: none;
}

.table__row_active,
.table__row_active > p,
.table__row:active,
.table__row:active > p {
  background: var(--service-color);
  color: var(--secondary-color);
}

.table__row:nth-child(2n) {
  background: var(--secondary-color);
}

.table__title {
  text-transform: uppercase;
  color: var(--secondary-color);
  padding: 16px;
}

.table__cell {
  font-weight: 500;
  color: var(--main-color);
  padding: 10px 16px;
}

.table__row_footer {
  border-radius: 0px 0px 20px 20px;
  background: var(--main-color);
  padding: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  color: var(--secondary-color);
}

.table__row_footer > span {
  font-weight: 700;
}

.loaderWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* @media (max-width: 1300px) {
  .table {
    border-radius: 10px 10px 0px 0px;
    font-size: 12px;
    line-height: 15px;
  }

  .table__row_head,
  .table__row {
    grid-template-columns: 20px 58px 50px 100px 125px 1fr;
  }

  .table__row_head {
    border-radius: 10px 10px 0px 0px;
  }

  .table__title {
    padding: 8px;
  }

  .table__cell {
    padding: 6px 8px;
  }

  .table__row_footer {
    border-radius: 0px 0px 10px 10px;
    padding: 8px;
    font-size: 12px;
    line-height: 15px;
  }

  .circle {
font-size: 20px;
  }
} */
