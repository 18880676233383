.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card p {
  font-size: 24px;
  color: #788bb1;
  font-weight: 600;
}

.buttonsEventList {
  display: flex;
  gap: 12px;
}

.buttonsEventList button {
  font-size: 20px;
  padding: 12px 16px;
  color: white;
}

.buttonAlarm {
  background: rgb(188, 74, 74);
}

.buttonWarning {
  background: rgb(193, 156, 71);
}

@media (max-width: 1300px) {
  .card p {
    font-size: 12px;
  }

  .buttonsEventList {
    gap: 6px;
  }

  .buttonsEventList button {
    font-size: 10px;
    padding: 6px 8px;
  }
}
