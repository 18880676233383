.mainmenu {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.16);
  border-radius: 20px 0px 0px 20px;
  height: 100vh;
  width: 450px;
  position: relative;
}

.mainmenu_header {
  padding: 44px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mainmenu_groupbuttons {
  padding: 0 32px;
  display: flex;
  flex-direction: column;
}

.mainmenu_footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 40px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.mainmenu_footer > span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;
  color: #dadfea;
  margin-bottom: 24px;
}

@media (max-width: 1300px) {
  .mainmenu {
    width: 420px;
    border-radius: 16px 0px 0px 16px;
  }

  .mainmenu_header {
    background: var(--main-color);
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    padding: 46px 28px;
    gap: 28px;
  }

  .mainmenu_header h3 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 32px;
  }

  .mainmenu_groupbuttons {
    padding: 0 28px;
  }

  .mainmenu_footer {
    bottom: 0;
  }
}
