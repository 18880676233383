.roomCard {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}

.roomCard__titles {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.roomCard__titles > h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #788bb1;
}

.roomCard__infoCards {
  display: flex;
  gap: 28px;
}

.roomCard__infoCard {
  display: flex;
  flex-direction: column;
}

.roomCard__infoCard > p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;

  color: #a5b0c6;
}

.roomCard__infoCard > span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  color: #788bb1;
}

.roomCard__rightInfo {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media (max-width: 1300px) {
  .roomCard {
    gap: 16px;
  }

  .roomCard > img {
    gap: 64px;
  }

  .roomCard__titles {
    gap: 14px;
  }

  .roomCard__titles > h2 {
    font-size: 12px;
    line-height: 15px;
  }

  .roomCard__infoCards {
    gap: 14px;
  }

  .roomCard__infoCard > p {
    font-size: 12px;
    line-height: 15px;
  }

  .roomCard__infoCard > span {
    font-size: 12px;
    line-height: 15px;
  }

  .roomCard__rightInfo {
    gap: 16px;
  }
}
