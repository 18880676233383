.room-card {
  display: flex;
  border-width: 3px;
  border-style: solid;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  background: var(--background-zkps);
  align-items: center;
  text-decoration: none;
}

.room-card__status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 130px;
  max-width: 144px;
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  color: var(--secondary-color);
  word-break: break-all;
}

.room-card__status-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.room-card__status-subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.room-card__info {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 16px 16px 16px 0;
}

.room-card__info h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: var(--main-color);
  padding: 0;
  margin: 0;
  width: 100%;
  padding: 0 16px;
}

@media (max-width: 1300px) {
  .room-card {
    border-width: 2px;
  }

  .room-card__status {
    min-height: 132px;
    gap: 8px;
    min-width: 132px;
    max-width: 176px;
    font-weight: 800;
  }

  .room-card__status-title {
    font-size: 36px;
    line-height: 44px;
  }

  .room-card__status-subtitle {
    font-size: 26px;
    line-height: 32px;
  }

  .room-card > h3 {
    font-size: 30px;
    padding: 0 8px;
    line-height: auto;
  }
}
