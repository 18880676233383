.wrapper {
  position: fixed;
  z-index: 10;
  top: 160px;
  right: 40px;
  transition: 0.3s;
}

.body {
  display: flex;
  min-width: 424px;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  z-index: 2;
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(calc(100% + 60px));
}

@media (max-width: 1300px) {
  .wrapper {
    top: 100px;
    right: 18px;
  }

  .body {
    min-width: 230px;
    padding: 8px 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.16);

  }
}