
.trains__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
  
  .trains__cell__span {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .trains__cell__span > span {
    cursor: pointer;
    width: 144px;
    height: 72px;
    background: #ffffff;
    border: 3px solid #788bb1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  
    color: #788bb1;
  }
  
  .trains__cell-count {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .trains__cell-count > a {
    text-decoration: none;
    cursor: pointer;
    width: 144px;
    height: 72px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    background: #007be4;
    border-radius: 20px;
    color: #ffffff;
  }
  .trains__cell-count > p {
    max-width: 90px;
  }
  .trains__cell__span > p {
    max-width: 146px;
  }