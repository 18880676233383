.debugging-page__wrapper {
  display: grid;
  grid-template-columns: repeat(6, 120px);
  gap: 20px;
}

.debugging-page__ppku-card {
  border-radius: 10px;
  padding: 16px;
  text-align: center;
  color: var(--secondary-color);
  font-weight: 700;
  font-size: 24px;
}
