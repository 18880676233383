.card {
  display: flex;
  gap: 16px;
  width: 100%;
}

.card_indicator {
  text-decoration: none;
  border-radius: 20px;
  max-width: 412px;
  width: 100%;
  height: 264px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
  text-align: center;
  font-family: Montserrat;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card_infobox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.card_infobox > span {
  color: var(--main-color);
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}

.card_infobox_bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.card_devices {
  display: flex;
  gap: 16px;
}

.text_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Montserrat;
}

.text_box > p {
  color: #a5b0c6;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}
