.device-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.device-status {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 12px;
  border: 1px solid gray;
  padding: 12px 20px;
  border-radius: 24px;
  color: white;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

.device-status_test {
  border-color: var(--test-color);
  background: var(--test-color);
}

.device-status_initial {
  border-color: gray;
  background: gray;
}

.device-status_online {
  border-color: var(--success-color);
  background: var(--success-color);
}

.device-status_success {
  border-color: var(--success-color);
  background: var(--success-color);
}

.device-status_offline {
  border-color: var(--offline-color);
  background: var(--offline-color);
}

.device-status_founded {
  border-color: var(--founded-color);
  background: var(--founded-color);
}

.device-status_warning {
  border-color: var(--warn-color);
  background: var(--warn-color);
}

.device-status_alarm {
  border-color: var(--danger-color);
  background: var(--danger-color);
}

.device__info-types,
.device__info-address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.device__actions {
  display: flex;
  gap: 16px;
}

.device__actions > button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 32px;
  gap: 10px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #788bb1;
  background: #ffffff;
  border: 3px solid #788bb1;
  border-radius: 48px;
}

.device__actions > button:active {
  background: #788bb1;
  color: white;
}
