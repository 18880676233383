.status-page__progress-bars {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 24px;
}

.status-page__bottom-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 40px 64px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 1300px) {
  .status-page__progress-bars {
    padding-top: 16px;
    margin-bottom: 16px;
  }
}
