.sidebar_item {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  border-radius: 20px;
  border-width: 3px;
  border-style: solid;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.sidebar_item:nth-child(1),
.sidebar_item:nth-child(4) {
  margin-bottom: 54px;
}

@media (max-width: 1300px) {
  .sidebar_item {
    max-width: 72px; 
    width: 72px;
    border-radius: 16px;
    padding: 4px;
    font-size: 0;
    text-indent: -9999px;
    height: 72px;
  }

  .sidebar_item > span {
    display: none;
  }

  .sidebar_item:nth-child(4) {
    margin-bottom: 16px;
  }

  .sidebar_item:nth-child(1),
  .sidebar_item:nth-child(5) {
    margin-bottom: 34px;
  }
}

@media (min-width: 2000px) {
  .sidebar_item {
    gap: 20px;
    width: 100%;
    padding: 20px;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .sidebar_item:nth-child(1),
  .sidebar_item:nth-child(4) {
    margin-bottom: 64px;
  }
}
