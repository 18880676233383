.card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 37px 0px;
  text-decoration: none;
  width: 197px;
  height: 133px;
  border-radius: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  color: var(--secondary-color);
}

@media (max-width: 1300px) {
  .card {
    padding: 20px 0px;
    width: 106px;
    height: 72px;
    font-size: 24px;
    line-height: 29px;
    border-radius: 10px;
  }
}
