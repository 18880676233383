.app-body__header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 48px;
  min-height: 96px;
  justify-content: space-between;
}

.header__text > h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #788bb1;
  text-transform: uppercase;
}

.header__text > p {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #788bb1;
}

.header__navigation {
  display: flex;
  align-items: center;
}

.header__right-tools {
  display: flex;
  gap: 52px;
}

.main-menu {
  position: fixed;
  top: 0;
  z-index: 20;
  transition: 0.3s;
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.main-menu__overlay {
  z-index: 19;
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
}

.main-menu_open {
  right: 0;
}

.main-menu_close {
  right: -450px;
}

.modal-info {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.modal-info > pre {
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #788bb1;
}

.button-removedb {
  align-self: flex-end;
  background-color: #788bb1;
  color: white;
  padding: 12px 16px;
  font-size: 20px;
}

.header_info-box {
  display: none;
  justify-content: center;
}

@media (max-width: 1300px) {
  .app-body__header {
    min-height: 52px;
    margin-bottom: 20px;
  }

  .header__text > h2 {
    font-size: 34px;
    line-height: normal;
  }

  .header__text > p {
    font-size: 26px;
    line-height: normal;
  }

  .header__right-tools {
    gap: 16px;
  }

  .header__right-tools > button > img {
    width: 100%;
  } 

  .modal-info {
    padding: 10px 8px;
    gap: 16px;
  }
  .modal-info > pre {
    font-size: 12px;
    line-height: 15px;
  }
  
  .button-removedb {
    padding: 6px 8px;
    font-size: 10px;
  }

  .header_info-box {
    display: flex;
    margin-right: 20px;
    padding: 14px 20px;
    background: var(--main-color);
    color: var(--secondary-color);
    min-width: 100px;
    border-radius: 16px;
    font-size: 36px;
    font-weight: 600;
  }
}