.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
  max-width: 370px;
  width: 100%;
  padding: 12px 28px 32px;
  background: #ffffff;
  height: 100vh;
  position: sticky;
  top: 0;
}

.sidebar__alarm {
  color: red;
  font-weight: 700;
  position: absolute;
  top: 140px;
}

.sidebar__navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 36px;
}

.sidebar__volume {
  max-width: 124px;
  height: 124px;
  margin-bottom: 36px;
}

.sidebar__date {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #788bb1;
}

.sidebar__date > p:last-child {
  font-weight: 500;
  font-size: 64px;
  line-height: 78px;
}

@media (max-width: 1300px) {
  .sidebar__navigation {
    margin-bottom: 0;
    align-items: flex-start;
  }
  
  .sidebar {
    max-width: 104px;
    padding: 16px 6px 16px 16px;
  }

  .sidebar__volume {
    max-width: 82px;
    height: 82px;
    margin-bottom: 24px;
  }
}

@media (min-width: 2000px) {
  .sidebar {
    max-width: 460px;
    padding: 32px 24px;
  }

  .sidebar__volume {
    max-width: 148px;
    height: 148px;
    margin-bottom: 40px;
  }
}
