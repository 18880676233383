.grid {
  display: grid;
  grid-template-columns: repeat(7, 197px);
  row-gap: 16px;
  column-gap: 16px;
  padding-right: 20px;
}

@media (max-width: 1967px) {
  .grid {
    grid-template-columns: repeat(6, 197px);
  }
}

@media (max-width: 1760px) {
  .grid {
    grid-template-columns: repeat(5, 197px);
  }
}

@media (max-width: 1612px) {
  .grid {
    grid-template-columns: repeat(4, 197px);
  }
}

@media (max-width: 1300px) {
  .grid {
    row-gap: 8px;
    column-gap: 8px;
    padding-right: 10px;
    grid-template-columns: repeat(7, 106px);
  }
}
