.operator-change__header {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.operator-change__header > p {
}

.operator-change__menu {
  position: absolute;
  right: 0;
}

.operator-change__event-statuses {
  display: flex;
}

@media (max-width: 1300px) {
  .operator-change__header {
    gap: 56px;
    margin-bottom: 50px;
  }

  .operator-change__header > p {
    max-width: 386px;
    font-size: 26px;
    font-weight: 500;
    color: var(--main-color);
  }

  .operator-change__event-statuses {
    gap: 16px;
  }

  .operator-change__checkboxes {
    margin-top: 42px;
    margin-left: 120px;
    display: flex;
    flex-direction: column;
  }
}
