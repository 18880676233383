.settings-network {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  align-self: flex-start;
}

.settings-network > p {
  margin-bottom: 12px;
  color: #788bb1;
  font-size: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.settings-network > .settings-network__input {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 3px solid var(--main, #788bb1);
  background: #fff;
  color: #788bb1;
  text-align: center;
  font-size: 36px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 144px;
  height: 72px;
}

.settings-network__button {
  margin-top: 24px;
}

.react-select__control {
  height: 98px;
  border-radius: 20px !important;
  border: 3px solid #788bb1 !important;
  background: #fff;
}

.react-select__menu {
  width: 400px !important;
}

@media (max-width: 1300px) {
  .settings-network {
    margin-bottom: 12px;
  }

  .settings-network > p {
    margin-bottom: 6px;
    font-size: 12px;
  }

  .settings-network > .settings-network__input {
    border-radius: 10px;
    border-width: 2px;
    font-size: 16px;
    width: 72px;
    height: 36px;
  }

  .settings-network__button {
    margin-top: 12px;
  }

  .react-select__control {
    height: 48px;
    border-radius: 10px !important;
    border-width: 2px !important;
  }

  .react-select__menu {
    width: 200px !important;
  }
}
