.title {
  border-radius: 32px;
  background: var(--main-color);
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 20px;
}

.numpad {
  background: #f5f9fa;
  border-radius: 32px;
  display: flex;
  width: 100%;
  gap: 16px;
}

.numpad__firstColumn {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.numpad__input {
  position: relative;
}

.input__toggleButton {
  position: absolute;
  top: 16px;
  right: 16px;
}

.numpad__input > input {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 17px;
  gap: 37px;
  left: 32px;
  height: 96px;
  width: 100%;
  top: 32px;
  background: #fef8d9;
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;

  color: #788bb1;
}

.numpad__buttons {
  display: grid;
  grid-template-columns: repeat(5, 96px);
  gap: 16px;
}

.numpad__buttons > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  background: #ffffff;
  border: 3px solid #788bb1;
  border-radius: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  color: #788bb1;
}

.numpad__buttons > button:active {
  background: #788bb1;
  color: #ffff;
}

.numpad__tools {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.numpad__tools > button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 204px;
  height: 96px;

  background: #ffffff;

  border: 3px solid #788bb1;
  border-radius: 20px;
}

.numpad__tools > button:active {
  background: #788bb1;
  color: #ffff;
}

@media (max-width: 1300px) {
  .title {
    border-radius: 16px;
    font-size: 14px;
    padding: 10px;
  }

  .numpad {
    border-radius: 16px;
    gap: 8px;
  }

  .numpad__firstColumn {
    gap: 8px;
  }

  .input__toggleButton {
    top: 8px;
    right: 8px;
  }

  .numpad__input > input {
    padding: 0px 8px;
    gap: 14px;
    left: 16px;
    height: 48px;
    top: 16px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 19px;
  }

  .numpad__buttons {
    grid-template-columns: repeat(5, 48px);
    gap: 8px;
  }

  .numpad__buttons > button {
    width: 48px;
    height: 48px;
    border-width: 2px;
    border-radius: 10px;
    font-size: 14px;
    line-height: auto;
  }

  .numpad__tools {
    gap: 8px;
  }

  .numpad__tools > button {
    width: 102px;
    height: 48px;
    border-width: 2px;
    border-radius: 10px;
  }

  .numpad__tools > button img {
    max-width: 24px;
  }
}
