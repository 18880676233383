.topology-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 128px);
  row-gap: 16px;
  column-gap: 16px;
  padding-right: 20px;
}

@media (max-width: 1300px) {
  .topology-grid {
    grid-template-columns: repeat(10, 70px);
    row-gap: 8px;
    column-gap: 8px;
    padding-right: 10px;
  }
}
