.paginate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 40px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.paginateInfo {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--main-color);
}

.paginateInfo > span {
  font-weight: 700;
}

.paginatePageInfo {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--main-color);
  margin-right: 76px;
}

.paginatePageInfo > span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  color: var(--main-color);
}

.paginateTools {
  display: flex;
  align-items: center;
}

.paginateButtonLeft,
.paginateButtonRight {
  border: 3px solid var(--main-color);
  background: var(--secondary-color);
  width: 128px;
  height: 88px;
  stroke: var(--main-color);
}

.paginateButtonLeft:active,
.paginateButtonRight:active {
  stroke: var(--secondary-color);
  background: var(--main-color);
}

.paginateButtonLeft:disabled,
.paginateButtonRight:disabled {
  border-color: rgba(120, 139, 177, 0.25);
  stroke: rgba(120, 139, 177, 0.25);
}
.paginateButtonLeft {
  border-radius: 20px 0px 0px 20px;
  margin-right: 16px;
}

.paginateButtonRight {
  background: var(--secondary-color);
  /* Main */

  border: 3px solid var(--main-color);
  border-radius: 0px 20px 20px 0px;
}

@media (max-width: 1300px) {
  .paginate {
    padding: 12px 20px;
  }

  .paginateInfo {
    font-size: 12px;
  }

  .paginatePageInfo {
    font-size: 12px;
    margin-right: 42px;
  }

  .paginatePageInfo > span {
    font-size: 24px;
  }

  .paginateButtonLeft,
  .paginateButtonRight {
    border-width: 2px;
    width: 70px;
    height: 48px;
  }

  .paginateButtonLeft > svg,
  .paginateButtonRight > svg {
    height: 28px;
    width: 14px;
  }

  .paginateButtonLeft {
    border-radius: 10px 0px 0px 10px;
    margin-right: 8px;
  }

  .paginateButtonRight {
    border-radius: 0px 10px 10px 0px;
  }
}
