.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 96px;
  background: var(--main-color);
  border-radius: 20px;
  margin-right: 28px;
}

@media (max-width: 1300px) {
  .button {
    width: 88px;
    height: 88px;
    margin-right: 16px;
    border-radius: 100%;
    background: var(--service-color);
  }

  .button > img {
    width: auto;
    height: auto;
  }
}
