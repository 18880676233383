.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapperInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  margin-bottom: 52px;
}

@media (max-width: 1300px) {
  .wrapperInputs {
    grid-gap: 8px;
    margin-bottom: 26px;
  }
}
