.rooms-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 16px;
  column-gap: 8px;
  padding-right: 20px;
}


@media (max-width: 1300px) {
  /* .rooms-grid__wrapper {
  overflow-y: scroll;
  } */
  .rooms-grid {
    grid-template-columns: repeat(2, 1fr);
    padding-right: 0;
    overflow-y: scroll;
  }
}
