.menuButton {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  color: var(--main-color);
  padding: 16px 6px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.menuButton:active {
  color: #dadfea;
}

.menuButton:active path {
  fill: #dadfea;
}

@media (max-width: 1300px) {
  .menuButton {
    font-size: 26px;
    line-height: 32px;
    gap: 24px;
    padding: 20px 0px;
  }
}