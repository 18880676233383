.card {
  text-align: center;
}

.card__title {
  color: var(--main-color);
}

.card__body {
}

.card__body > h2 {
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
}

.card__body > p {
  color: var(--main-color);
}

@media (max-width: 1300px) {
  .card {
  }

  .card__title {
    margin-bottom: 8px;
  }
  
  .card__body > p,
  .card__title {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 29px;
  }

  .card__body {
    border-width: 3px;
    border-style: solid;
    border-radius: 14px;
  }

  .card__body > h2 {
    font-size: 54px;

    height: 107px;
    width: 176px;
  }

  .card__body > p {
    padding: 8px 0;
  }
}
