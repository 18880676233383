.ReactModal__Content .modal__wrapper-controls {
  position: absolute;
  bottom: 0;
  left: -110px;
  z-index: 1000;
}

.modal-controls {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.modal-controls > button {
  width: 96px;
  height: 96px;
  background: var(--secondary-color);
  border-radius: 50%;
  color: var(--main-color);
  font-weight: 700;
  font-size: -webkit-xxx-large;
}

.ReactModal__Overlay {
  background: rgba(94, 94, 94, 0.22) !important;
}

@media (max-width: 1795px) {
  .ReactModal__Content {
    max-width: 1200px !important;
  }
}

@media (max-width: 1300px) {
  .ReactModal__Content {
    max-width: 600px !important;
  }
}