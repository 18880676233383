.row {
  display: flex;
  gap: 16px;
}

@media (max-width: 1300px) {
  .row {
    gap: 6px;
  }
}
