.progressBarComponent {
  width: 100%;
  border-radius: 20px;
  background: var(--main, #788bb1);
}

.progressBar {
  flex-shrink: 0;
  border-radius: 20px;
  border: 3px solid #788bb1;
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBarCounters > strong {
  color: var(--unnamed, #007be4);
  text-align: center;
  font-size: 44px;
  font-family: Montserrat;
  font-weight: 700;
  text-transform: uppercase;
}
.progressBarCounters > p {
  color: #788bb1;
  text-align: center;
  font-size: 36px;
  font-family: Montserrat;
  font-weight: 600;
  text-transform: uppercase;
}

.otherInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding: 12px 28px;
}

.otherInfoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 204px;
  width: 100%;
}

.otherInfoCard > p {
  color: var(--white, #fff);
  font-size: 24px;
  font-family: Montserrat;
  text-transform: capitalize;
}

.otherInfoCard > span {
  color: var(--white, #fff);
  text-align: center;
  font-size: 42px;
  font-family: Montserrat;
  font-weight: 600;
}

.circularBody {
  display: flex;
  justify-content: center;
  width: 208;
  margin-bottom: 26px;
}

.title {
  color: #788bb1;
  text-align: center;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 26px;
}

@media (max-width: 1822px) {
  .otherInfo {
    justify-content: space-around;
  }
  .otherInfoCard > p {
    font-size: 18px;
  }

  .otherInfoCard > span {
    font-size: 36px;
  }
}

@media (max-width: 1300px) {
  .title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .progressBar {
    padding: 30px;
  }

  .circularBody {
    width: 222px;
    margin-bottom: 0px;
  }

  .progressBarCounters > strong {
    font-size: 48px;
  }
  .progressBarCounters > p {
    font-size: 36px;
  }

  .otherInfo {
    padding: 6px 12px;
  }

  .otherInfoCard > p {
    font-size: 24px;
    font-weight: 500;
  }

  .otherInfoCard > span {
    font-size: 48px;
  }
}
